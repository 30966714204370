import _ from 'lodash'

export default class CopyUtil {
  /**
  * @desc 多層次的物件深拷貝
  * @param targetObject {object}
  * @return {object} 深拷貝後的物件
  */
  static iterateObjectDeepClone(targetObject: any) {
    let cloneObject = JSON.parse(JSON.stringify(targetObject));

    Object.keys(targetObject).forEach(key => {
      if (typeof targetObject[key] === 'object' ) {
        cloneObject[key] = _.cloneDeep(targetObject[key]);
      }
    });
    
    return cloneObject
  }
}